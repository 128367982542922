import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import RequireAuth from 'components/RequireAuth';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const SurveyList = Loadable(lazy(() => import('pages/survey/List')));
const SurveyForm = Loadable(lazy(() => import('pages/survey/Form')));
const SurveyResponse = Loadable(lazy(() => import('pages/survey/SurveyResponse')));
const SurveyStatistics = Loadable(lazy(() => import('pages/survey/SurveyStatistics')));

const UserListing = Loadable(lazy(() => import('pages/user/Listing')));
const UserEditForm = Loadable(lazy(() => import('pages/user/EditForm')));
const UserAddForm = Loadable(lazy(() => import('pages/user/AddForm')));

const CategoryListing = Loadable(lazy(() => import('pages/survey/category/Listing')));
const CategoryEditForm = Loadable(lazy(() => import('pages/survey/category/EditForm')));
const CategoryAddForm = Loadable(lazy(() => import('pages/survey/category/AddForm')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/admin',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: 'dashboard',
            element: (
                <RequireAuth>
                    <DashboardDefault />
                </RequireAuth>
            )
        },
        {
            path: 'survey',
            element: (
                <RequireAuth>
                    <SurveyList />
                </RequireAuth>
            )
        },
        {
            path: 'survey/:id',
            element: (
                <RequireAuth>
                    <SurveyForm />
                </RequireAuth>
            )
        },
        {
            path: 'survey/:id/response',
            element: (
                <RequireAuth>
                    <SurveyResponse />
                </RequireAuth>
            )
        },
        {
            path: 'survey/:id/statistics',
            element: (
                <RequireAuth>
                    <SurveyStatistics />
                </RequireAuth>
            )
        },
        {
            path: 'user',
            element: (
                <RequireAuth>
                    <UserListing />
                </RequireAuth>
            )
        },
        {
            path: 'user/:id',
            element: (
                <RequireAuth>
                    <UserEditForm />
                </RequireAuth>
            )
        },
        {
            path: 'user/add',
            element: (
                <RequireAuth>
                    <UserAddForm />
                </RequireAuth>
            )
        },
        {
            path: 'survey-category',
            element: (
                <RequireAuth>
                    <CategoryListing />
                </RequireAuth>
            )
        },
        {
            path: 'survey-category/:id',
            element: (
                <RequireAuth>
                    <CategoryEditForm />
                </RequireAuth>
            )
        },
        {
            path: 'survey-category/add',
            element: (
                <RequireAuth>
                    <CategoryAddForm />
                </RequireAuth>
            )
        },
        {
            path: '*',
            element: <>Trang không tồn tại</>
        }
    ]
};

export default MainRoutes;
