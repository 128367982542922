import fetchApi from '../lib/axios';

export const addSurvey = async (data) => {
    return fetchApi('/surveys', data, 'post');
};

export const getSurvey = async (id) => {
    return fetchApi(`/surveys/${id}`);
};

export const getSurveys = async (data) => {
    let query = '?';
    if (data?.keyword) {
        query = `${query}&keyword=${data.keyword}`;
    }

    if (data?.page) {
        query = `${query}&page=${data.page}`;
    }

    if (data?.perPage) {
        query = `${query}&per_page=${data.perPage}`;
    }

    if (data?.categoryId) {
        query = `${query}&categoryId=${data.categoryId}`;
    }

    if (data?.orderBy) {
        query = `${query}&orderBy=${data.orderBy}`;
    }

    if (data?.order) {
        query = `${query}&order=${data.order}`;
    }

    return fetchApi(`/surveys${query}`);
};

export const updateSurvey = async (id, data) => {
    return fetchApi(`/surveys/${id}`, data, 'put');
};

export const deleteSurvey = async (id) => {
    return fetchApi(`/surveys/${id}`, '', 'delete');
};

export const surveyResponse = async (id) => {
    return fetchApi(`/surveys/${id}/response`);
};

export const surveyStatistics = async (id) => {
    return fetchApi(`/surveys/${id}/statistics`);
};

export const getSurveyQuestionMoreAnswer = async (params) => {
    return fetchApi(`/surveys/${params.id}/questions/${params.questionId}/more-answer?per_page=${params.per_page}&page=${params.page}`);
};

export const getStatisticExport = async (id) => {
    return fetchApi(`/surveys/${id}/statistics/export`);
};
