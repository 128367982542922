import { call, put, takeLatest } from 'redux-saga/effects';
import { addSurveySuccess } from '../store/reducers/survey';
import * as surveyService from '../services/survey';

const addSurvey = function* ({ payload } = { onSuccess: () => {}, onError: () => {} }) {
    try {
        const { ...data } = payload;

        const survey = yield call(surveyService.addSurvey, data);
        yield put(addSurveySuccess(survey));
        payload.meta.onSuccess(survey);
    } catch (error) {
        payload.meta.onError();
    }
};

const watchers = function* () {
    yield takeLatest('survey/addSurvey', addSurvey);
};
export default watchers();
