import { call, put, takeLatest } from 'redux-saga/effects';
import { getCurrentUserSuccess, getCurrentUserError } from '../store/reducers/user';
import * as authService from '../services/auth';

const getCurrentUser = function* ({ payload } = { onSuccess: () => {}, onError: () => {} }) {
    try {
        const user = yield call(authService.getCurrentUser);
        yield put(getCurrentUserSuccess(user));
        payload.onSuccess(user);
    } catch (error) {
        yield put(getCurrentUserError(error));
        payload.onError();
    }
};

const watchers = function* () {
    yield takeLatest('user/getCurrentUser', getCurrentUser);
};
export default watchers();
