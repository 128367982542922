// material-ui
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <Typography variant="h5">ForLife - Khảo sát</Typography>;
};

export default Logo;
