import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/reducers/user';

const RequireAuth = ({ children, allowedRoles }) => {
    const location = useLocation();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

RequireAuth.propTypes = {
    allowedRoles: PropTypes.array,
    children: PropTypes.node
};

export default RequireAuth;
