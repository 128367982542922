import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import AuthLayout from 'layout/AuthLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

function RootElement() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/login');
    }, []);

    return <></>;
}

const LoginRoutes = {
    path: '/',
    element: <AuthLayout />,

    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        }
    ]
};

export default LoginRoutes;
