// project import
import dashboard from './dashboard';
import survey from './survey';
import user from './user';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [survey, user]
};

export default menuItems;
