// assets
import { FormOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import PersonIcon from '@mui/icons-material/Person';
// icons
const icons = {
    FormOutlined,
    UnorderedListOutlined,
    UserOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-user',
    title: 'Tải khoản',
    type: 'group',
    role: ['superadmin'],
    children: [
        {
            id: 'user',
            title: 'Tài khoản',
            type: 'item',
            url: '/admin/user',
            icon: icons.UserOutlined,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
