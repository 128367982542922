import LocalStorage from 'lib/localStorage';
import { useEffect } from 'react';
import { getCurrentUser } from '../store/reducers/user';
import { useDispatch } from 'react-redux';

const useAppInit = (setIsLoading) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            const token = await LocalStorage.getToken();
            if (token) {
                dispatch(
                    getCurrentUser({
                        onSuccess: () => {
                            setIsLoading(false);
                        },
                        onError: () => {
                            setIsLoading(false);
                        }
                    })
                );
            } else {
                setIsLoading(false);
            }
        };

        init();
    }, [dispatch, setIsLoading]);
};

export default useAppInit;
