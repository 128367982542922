// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import user from './user';
import survey from './survey';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    user,
    survey
});

export default reducers;
