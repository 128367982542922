// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isAuthenticated: false,
    currentUser: null
};

// ==============================|| SLICE - USER ||============================== //

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        getCurrentUser(state, action) {},
        getCurrentUserSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
        },
        getCurrentUserError: (state, action) => {
            state.currentUser = null;
            state.isAuthenticated = false;
        }
    }
});

export const { getCurrentUser, getCurrentUserError, getCurrentUserSuccess } = user.actions;

export const selectIsAuthenticated = (state) => state.user?.isAuthenticated;
export const selectCurrentUser = (state) => state.user?.currentUser;

export default user.reducer;
