import fetchApi from '../lib/axios';

export const login = async (email, password) => {
    return fetchApi(
        '/auth/login',
        {
            email,
            password
        },
        'post'
    );
};

export const getCurrentUser = async () => {
    return fetchApi('/auth/me', {}, 'post');
};
