// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    form: {
        name: '',
        short_description: ''
    }
};

// ==============================|| SLICE - USER ||============================== //

const survey = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        addSurvey: () => {},
        addSurveySuccess: (state, action) => {
            state.form = action.payload;
        },
        addSurveyError: (state, action) => {},
        getSurveyForm(state, action) {},
        getSurveyFormSuccess: (state, action) => {
            // state.currentUser = action.payload;
        },
        getSurveyFormError: (state, action) => {}
    }
});

export const { getSurveyForm, addSurvey, addSurveySuccess } = survey.actions;

export const selectSurveyForm = (state) => state.survey.form;

export default survey.reducer;
