function htmlDecode(input) {
    if (!input) return '';

    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
}

function detectMob() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export { htmlDecode, detectMob };
