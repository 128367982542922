export default class LocalStorage {
    static setToLocal = async (key, value) => {
        try {
            await localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log('LocalStorage SET ' + key + ' FROM LOCAL \n ERROR:', error.message);
        }
    };

    static getFromLocal = async (key) => {
        let data = '';
        try {
            let res = await localStorage.getItem(key);
            data = JSON.parse(res);
        } catch (error) {
            console.log('LocalStorage GET ' + key + ' FROM LOCAL \n ERROR:', error.message);
        }

        return data;
    };

    static getToken = async () => {
        const data = await LocalStorage.getFromLocal('token');
        return data || null;
    };

    static setToken = async (token = null) => {
        await LocalStorage.setToLocal('token', token);
    };

    static setLanguage = async (value = 'en') => {
        await LocalStorage.setToLocal('lang', value);
    };

    static setDataList = async (key, data = { data: [], expiredTime: '' }) => {
        await LocalStorage.setToLocal(key, JSON.stringify(data));
    };

    static getDataList = async (key = '') => {
        try {
            const dataStr = await LocalStorage.getFromLocal(key);

            const data = JSON.parse(dataStr);
            if (!isEmpty(data) && !isEmpty(data.data) && moment().diff(moment(data.expiredTime)) < -300000) {
                return data.data;
            }
            return null;
        } catch (error) {
            return null;
        }
    };
}
