// assets
import { FormOutlined, UnorderedListOutlined } from '@ant-design/icons';

// icons
const icons = {
    FormOutlined,
    UnorderedListOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-survey',
    title: 'Khảo sát',
    type: 'group',
    role: ['admin', 'superadmin'],
    children: [
        {
            id: 'survey-category',
            title: 'Danh mục',
            type: 'item',
            url: '/admin/survey-category',
            role: ['superadmin'],
            icon: icons.UnorderedListOutlined,
            breadcrumbs: true
        },
        {
            id: 'survey',
            title: 'Danh sách khảo sát',
            type: 'item',
            url: '/admin/survey',
            role: ['admin', 'superadmin'],
            icon: icons.UnorderedListOutlined,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
