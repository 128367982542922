// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            {/* {!matchesXs && <Search />} */}
            {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

            <Profile />
        </div>
    );
};

export default HeaderContent;
