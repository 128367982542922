import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const AuthLayout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/login');
    }, []);

    return (
        <>
            <Outlet />
        </>
    );
};

export default AuthLayout;
