import axios from 'axios';
import LocalStorage from './localStorage';

const TIMEOUT = 18000;

export const funcFetch = async (endPoint, data = null, method = 'get', headers = {}) => {
    headers['Accept-Language'] = 'vn';

    const token = await LocalStorage.getToken();

    if (token) {
        headers = {
            ...headers,
            Authorization: 'Bearer ' + token
        };
    }

    return axios({
        url: process.env.REACT_APP_API_URL + endPoint,
        method,
        headers,
        data,
        timeout: TIMEOUT
    })
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data.data;
            }

            return Promise.reject(res.error);
        })
        .catch((err) => {
            throw new Error(err.message);
        });
};

const fetchApi = async (endPoint = '', data = null, method = 'get', headers = {}) => {
    return funcFetch(endPoint, data, method, headers);
};

export default fetchApi;
