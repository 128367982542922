// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/reducers/user';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const currentUser = useSelector(selectCurrentUser);

    const navGroups = menuItem.items.map((item) => {
        if (!item.role || item?.role?.includes(currentUser.role)) {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={`group-${item.id}`} item={item} />;
                default:
                    return (
                        <Typography key={`typo-${item.id}`} variant="h6" color="error" align="center">
                            Fix - Navigation Group
                        </Typography>
                    );
            }
        } else {
            return <></>;
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
