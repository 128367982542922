import React from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import { ToastContainer } from 'react-toastify';
import ScrollTop from 'components/ScrollTop';
import useAppInit from 'hooks/useAppInit';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import './assets/css/common.scss';
const App = () => {
    const [isLoading, setIsLoading] = React.useState(true);

    useAppInit(setIsLoading);

    return (
        <ThemeCustomization>
            <ScrollTop>
                {!isLoading && <Routes />}
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ScrollTop>
        </ThemeCustomization>
    );
};

export default App;
